export const environment = {
    production: false,
    auth: {
        clientID: '2VhkpOFwRVduUYcpGNWMyhX5JA5sLBWX',
        domain: 'auth0.flyht.dev',
        redirect: 'https://ai-flyhtturn.flyht.dev/',
        scope: 'openid sub app_metadata user_id name'
    },
    initFlightTurn: 'https://sd06au5m16.execute-api.us-west-2.amazonaws.com/dev',
    calendarKey: '0412765026-fcs-1617217872',
    idTokenValidSeconds: 36000 /* This value should be the same as the Auth0 timeout value */,
    flyhtSocket: 'wss://bkh3zvgosi.execute-api.us-west-2.amazonaws.com/production?t=',
    userInfo: 'https://sd06au5m16.execute-api.us-west-2.amazonaws.com/dev/user',
    dailyRoster: 'https://sd06au5m16.execute-api.us-west-2.amazonaws.com/dev/schedule/dailyRoster?airportIcaoCode=',
    employeeQualifications: 'https://sd06au5m16.execute-api.us-west-2.amazonaws.com/dev/employee',
    employeeQualificationById: 'https://sd06au5m16.execute-api.us-west-2.amazonaws.com/dev/employee?employeeId=',
    qualifications: 'https://sd06au5m16.execute-api.us-west-2.amazonaws.com/dev/qualification',
    scheduleCrew: 'https://sd06au5m16.execute-api.us-west-2.amazonaws.com/dev/schedule/crew'
};