import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AppConstants {
    notificationDuration: number = 600000;
    lightColor: string = "#fff";
    darkColor: string = "#1B66B1"

    planeSVG: object = {
      path: "M23.333,9.333H17.778L12.669.392A.778.778,0,0,0,11.993,0H8.809a.777.777,0,0,0-.748.991l2.383,8.342h-5l-2.1-2.8a.778.778,0,0,0-.622-.311H.778a.778.778,0,0,0-.754.966l1.532,5.256L.024,17.7a.778.778,0,0,0,.754.966H2.722a.777.777,0,0,0,.622-.311l2.1-2.8h5L8.061,23.9a.778.778,0,0,0,.748.992h3.184a.778.778,0,0,0,.675-.392l5.11-8.942h5.556c1.718,0,4.667-1.393,4.667-3.111S25.052,9.333,23.333,9.333Z",
      fill: this.lightColor
    };

    arrivalPlaneSVG: object = {
      path: "M27.3,20.449H.7a.7.7,0,0,0-.7.7v1.4a.7.7,0,0,0,.7.7H27.3a.7.7,0,0,0,.7-.7v-1.4A.7.7,0,0,0,27.3,20.449ZM3.524,15.78a1.443,1.443,0,0,0,1.064.469l5.711-.008a2.871,2.871,0,0,0,1.3-.311L24.325,9.47a7.437,7.437,0,0,0,2.932-2.551c.8-1.246.888-2.148.572-2.785s-1.082-1.106-2.548-1.2a7.349,7.349,0,0,0-3.775.852L17.2,5.972,7.628,2.382a.779.779,0,0,0-.787-.049L3.964,3.794a.8.8,0,0,0-.226,1.248l6.834,4.292L6.057,11.625l-3.165-1.6a.779.779,0,0,0-.7,0l-1.756.891A.8.8,0,0,0,.19,12.15Z",
      fill: this.lightColor
    };

    departurelPlaneSVG: object = {
      path: "M27.3,19.6H.7a.7.7,0,0,0-.7.7v1.4a.7.7,0,0,0,.7.7H27.3a.7.7,0,0,0,.7-.7V20.3A.7.7,0,0,0,27.3,19.6ZM1.96,9l3.882,3.5a2.739,2.739,0,0,0,1.114.609L19.54,16.535a6.69,6.69,0,0,0,3.544.059c1.3-.362,1.9-.928,2.067-1.562s-.076-1.431-1.022-2.4a6.765,6.765,0,0,0-3.1-1.75L16.763,9.716,12.373,1.322A.727.727,0,0,0,11.862.8L9.015.025a.722.722,0,0,0-.906.719l2.1,7.184L5.734,6.711,4.527,3.741a.723.723,0,0,0-.482-.434L2.307,2.833a.723.723,0,0,0-.907.695L1.41,7.98A1.509,1.509,0,0,0,1.96,9Z",
      fill: this.lightColor
    };

    airlines: any = [
      {
        iata: 'WS',
        icao: 'WJA'
      },
      {
        iata: 'WO',
        icao: 'WSW'
      },
      {
        iata: 'WR',
        icao: 'WEN',
        aliases: ['WS'],
      },
      {
        iata: 'AC',
        icao: 'ACA'
      },
      {
        iata: 'F8',
        icao: 'FLE'
      },
      {
        iata: 'QK',
        icao: 'JZA',
        callSign: 'JAZZ',
        defaultAircraftIcao: 'DH8D',
        aliases: ['AC8', 'QK'],
      },
      {
        iata: 'KL',
        icao: 'KLM'
      },
      {
        iata: 'AF',
        icao: 'AFR'
      },
      {
        iata: 'WG',
        icao: 'SWG'
      },
    ];
}
