import { Component, OnInit } from '@angular/core';
import { EmployeeService } from '../services/employee.service';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss']
})
export class EmployeeComponent implements OnInit {

  employeeData: any;
  displayDialog: boolean = false;
  qualifications: any;
  selectedQualifications: any;
  selectedEmployee: any;
  addedQualifications: any = [];
  removedQualifications: any = [];
  valueCount: number = 0;

  constructor(private employeeService: EmployeeService) { }

  ngOnInit(): void {
    this.populateEmployeeDate();
    this.employeeService.getAllQualifications().subscribe((data: any) => {
      console.log(data);
      this.qualifications = data.message;
    });

  }

  populateEmployeeDate() {
    this.employeeService.getAllEmployees().subscribe((data: any) => {
      this.employeeData = data.message;
    });
  }

  onRowEditInit(employee) {
    console.log(employee);
    this.selectedEmployee = JSON.parse(JSON.stringify(employee));
    this.valueCount = this.selectedEmployee.qualifications;
    this.displayDialog = true;
    this.selectedQualifications = [];
    this.addedQualifications = [];
    this.removedQualifications = [];
    employee.qualifications.forEach((qualification: any) => {
      let q = this.qualifications.find(element => element.displayName === qualification.displayName);
      this.selectedQualifications.push(q);
    });
  }

  onClose() {
    this.displayDialog = false;
  }

  onSave() {
    let original = this.selectedEmployee.qualifications.map(a => a.displayName);
    let qua = this.selectedQualifications.map(a => a.displayName);

    original.forEach(element => {
      if (qua.indexOf(element) === -1) {
        let q = this.qualifications.find(qualification => qualification.displayName === element);
        this.removedQualifications.push(q);
      }
    });

    qua.forEach(element => {
      if (original.indexOf(element) === -1) {
        let q = this.qualifications.find(qualification => qualification.displayName === element);
        this.addedQualifications.push(q);
      }
    });

    this.selectedEmployee.addQualifications = this.addedQualifications;
    this.selectedEmployee.deleteQualifications = this.removedQualifications;
    this.employeeService.updateEmployeeQualifications(this.selectedEmployee).subscribe(data => {
      this.displayDialog = false;
      this.populateEmployeeDate();
    });

  }

}
