import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CalendarOptions, FullCalendarComponent } from '@fullcalendar/angular';
import { EventService } from '../services/event.service';
import { ObservationDataService } from './../services/observation-data.service';
import { DomHandler } from 'primeng/dom';
import { environment } from './../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { AppConstants } from './../common/appConstants';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  options: CalendarOptions;
  @ViewChild('calendar') calendar: FullCalendarComponent;
  tooltip: any;
  barColors: object;
  planeSVG: any = this.appConstants.planeSVG;
  arrivalSVG: any = this.appConstants.arrivalPlaneSVG;
  departureSVG: any = this.appConstants.departurelPlaneSVG;

  oldFilteredAirlineICAOs: Array<string> = [];

  airlines: any = [];

  turnData: any = [];

  constructor(
    public obsDataService: ObservationDataService,
    private eventService: EventService,
    public el: ElementRef,
    public zone: NgZone,
    private route: ActivatedRoute,
    public appConstants: AppConstants
  ) {
    this.barColors = {
      'on time': 'linear-gradient(180deg, #029100 0%,  #014900 100%)',
      early: 'linear-gradient(180deg, #d6efd2 0%,  #507f50 100%)',
      delayed: 'linear-gradient(180deg, #c83b35 0%,  #641e1b 100%)',
      cancelled:
        'repeating-linear-gradient(135deg, #FF0000, #FF0000 2px, #fff 2px, #fff 4px)',
    };
  }

  ngOnInit(): void {
    this.eventService.setUserInfo(this.route.snapshot.data.user.message);

    this.airlines = this.getUserAirlines(this.appConstants.airlines)
    this.obsDataService.filteredAirlines = this.airlines;
    this.oldFilteredAirlineICAOs = this.obsDataService.filteredAirlineICAOs;
    this.options = {
      schedulerLicenseKey: environment.calendarKey,
      headerToolbar: {
        left: 'timeGrid6Hrs,timeGrid12Hrs,timeGrid24Hrs',
        center: 'prev title next',
        right: '',
      },
      nowIndicator: false,
      initialView: 'timeGrid6Hrs',
      views: {
        timeGrid6Hrs: {
          type: 'resourceTimeline',
          buttonText: '6 hrs',
          slotDuration: '00:30',
          duration: { hours: 6 },
        },
        timeGrid12Hrs: {
          type: 'resourceTimeline',
          buttonText: '12 hrs',
          slotDuration: '00:30',
          duration: { hours: 12 },
        },
        timeGrid24Hrs: {
          type: 'resourceTimeline',
          buttonText: '24 hrs',
          slotDuration: '00:30',
          duration: { hours: 24 },
        },
      },
      validRange: (currentDate) => {
        let visibleRange = this.getVisibleRange(currentDate);
        return { start: visibleRange.startDate, end: visibleRange.endDate };
      },
      editable: false,
      height: 'auto',
      resourceAreaWidth: '25%',
      filterResourcesWithEvents: true,
      initialDate: new Date().getTime() - 1 * 60 * 60 * 1000,
      titleFormat: {
        month: 'short',
        year: 'numeric',
        day: 'numeric',
        weekday: 'short',
      },
      slotLabelFormat: [{ hour: 'numeric', minute: '2-digit', hour12: false }],
      resourceAreaColumns: [
        {
          field: 'arrival',
          headerContent: (arg) => {
            return {
              html: `<label>Arrival</label></div>`,
            };
          },
          width: 80,
        },
        {
          field: 'departure',
          headerContent: (arg) => {
            return {
              html: `<label>Departure</label></div>`,
            };
          },
          width: 100,
        },
        {
          field: 'type',
          headerContent: (arg) => {
            return {
              html: `<label>Type</label></div>`,
            };
          },
          width: 80,
        },
      ],
      eventClick: (info) => {
        console.log(info);
      },
      eventMouseEnter: (mouseEnterInfo) => {
        this.createTooltip(mouseEnterInfo);
      },
      eventMouseLeave: (mouseLeaveInfo) => {
        this.tooltip.remove();
      },
      eventDidMount: (info) => {
        info.el.style.background =
          this.barColors[info.event.extendedProps.status.toLowerCase()];
      },
    };
    this.obsDataService.fetchData();

    this.eventService.getInitFlighturns().subscribe((data: any) => {
      data = data.message;

      data.forEach((item) => {
        if (item.status) {
          this.addEventToCalendar(item);
          this.turnData.push(item);
        }
      });

      console.log(this.turnData);
    });

    this.obsDataService.flyhtTurnNewData.subscribe((data: any) => {
      this.turnData.push(data);
      if (this.airlineFilter(data.airlineIcao)) {
        this.addEventToCalendar(data);
      }
    });

    this.obsDataService.flyhtTurnUpdateData.subscribe((data: any) => {
      // update the turn in the turn data
      let updatedTurn = this.turnData.find(
        (turn) => turn.turnId === data.turnId
      );
      updatedTurn = data;

      // update the turn in the schedule
      if (this.airlineFilter(data.airlineIcao)) {
        if (this.calendar.getApi()) {
          let resource = this.calendar
            .getApi()
            .getResourceById(data.resourceId);
          if (resource) {
            let events = resource.getEvents();
            if (events.length > 0) {
              events[0].remove();
              this.addCalendarItem(data);
            }
          }
        }
      }
    });

    this.obsDataService.flyhtTurnDeleteData.subscribe((data: any) => {
      // update the turn in the turn data
      let updatedTurnIndex = this.turnData.findIndex(
        (turn) => turn.turnId === data.turnId
      );
      this.turnData.splice(updatedTurnIndex, 1);

      if (this.airlineFilter(data.airlineIcao)) {
        let resource = this.calendar.getApi().getResourceById(data.resourceId);
        if (resource) {
          let events = resource.getEvents();
          if (events.length > 0) {
            events[0].remove();
          }
        }
      }
    });
  }

  getUserAirlines(airlines) {
    let getUserAirlines= this.eventService.userInfo.airlineIcaoCodes;
    return airlines.filter(airline => getUserAirlines.includes(airline.icao))
  }

  airlineFilter(airlineIcao) {
    return this.obsDataService.filteredAirlineICAOs.includes(airlineIcao);
  }

  airlineChange(event) {
    let airlinesToAdd = [];

    // if newArrayItem not in old array, then add it
    this.obsDataService.filteredAirlineICAOs.forEach((airline) => {
      if (!this.oldFilteredAirlineICAOs.includes(airline)) {
        airlinesToAdd.push(airline);
      }
    });

    if (airlinesToAdd.length) {
      let turnsToAdd = this.turnData.filter((turn) =>
        airlinesToAdd.includes(turn.airlineIcao)
      );

      turnsToAdd.forEach((turn) => this.addEventToCalendar(turn));
    }

    let airlinesToRemove = [];
    // if old array item not in new array, then add it
    this.oldFilteredAirlineICAOs.forEach((airline) => {
      if (!this.obsDataService.filteredAirlineICAOs.includes(airline)) {
        airlinesToRemove.push(airline);
      }
    });

    if (airlinesToRemove.length) {
      // remove resources
      let resources = this.calendar.getApi().getResources();

      resources.forEach((resource) => {
        if (airlinesToRemove.includes(resource.extendedProps.airlineIcao)) {
          resource.remove();
        }
      });

      // remove events
      let events = this.calendar.getApi().getEvents();

      events.forEach((event) => {
        if (airlinesToRemove.includes(event.extendedProps.airlineIcao)) {
          event.remove();
        }
      });
    }

    this.oldFilteredAirlineICAOs = this.obsDataService.filteredAirlineICAOs;
  }

  addEventToCalendar(item) {
    if (item.airlineIcao === "WJA" && item.aircraftTypeName === "Q400") {
      item.airlineIcao = "WEN";

      if (item.arrivingFlight) {
        item.arrivingFlight.flightNumberIata = item.arrivingFlight.flightNumberIata.replace("WS", "WR");
        item.arrivingFlight.flightNumberIcao = item.arrivingFlight.flightNumberIcao.replace("WJA", "WEN");
      }
      if (item.departingFlight) {
        item.departingFlight.flightNumberIata = item.departingFlight.flightNumberIata.replace("WS", "WR");
        item.departingFlight.flightNumberIcao = item.departingFlight.flightNumberIcao.replace("WJA", "WEN");
      }
    }

    if (this.calendar.getApi()) {
      this.calendar.getApi().addResource({
        id: item.turnId,


        arrival: item.arrivingFlight
          ? item.arrivingFlight.flightNumberIata
          : '',
        departure: item.departingFlight
          ? item.departingFlight.flightNumberIata
          : '',
        type: item.aircraftTypeName,
        airlineIcao: item.airlineIcao,
      });
      this.addCalendarItem(item);
    }
  }

  addCalendarItem(item) {
    if (item.status === 'departure_cancelled') {
      this.calendar.getApi().addEvent({
        resourceId: item.turnId,
        id: item.turnId,
        start: this.changeTimeZoneOffset(
          new Date(item.turnStartEstimatedUtc),
          item.airportTimeZoneOffset
        ),
        end: this.changeTimeZoneOffset(
          new Date(item.turnEndEstimatedUtc),
          item.airportTimeZoneOffset
        ),
        color: item.color,
        status: item.status,
        cancelColor: item.departingFlight.color,
        airlineIcao: item.airlineIcao,
      });
    } else {
      this.calendar.getApi().addEvent({
        resourceId: item.turnId,
        id: item.turnId,
        start: this.changeTimeZoneOffset(
          new Date(item.turnStartEstimatedUtc),
          item.airportTimeZoneOffset
        ),
        end: this.changeTimeZoneOffset(
          new Date(item.turnEndEstimatedUtc),
          item.airportTimeZoneOffset
        ),
        borderColor: item.color,
        status: item.status,
        airlineIcao: item.airlineIcao,
      });
    }
  }

  changeTimezone(date, timeZone) {
    let invdate = new Date(
      date.toLocaleString('en-US', {
        timeZone: timeZone,
      })
    );
    let diff = date.getTime() - invdate.getTime();
    return new Date(date.getTime() - diff);
  }

  changeTimeZoneOffset(date, offset) {
    let utc = date.getTime() + date.getTimezoneOffset() * 60000;
    return new Date(utc + 3600000 * offset);
  }

  getVisibleRange(currentDate) {
    let startDate = new Date(currentDate.valueOf());
    let endDate = new Date(currentDate.valueOf());

    // Adjust the start & end dates, respectively
    startDate.setDate(startDate.getDate() - 1); // One day in the past
    endDate.setDate(endDate.getDate() + 2); // Two days into the future
    return { startDate: startDate, endDate: endDate };
  }

  createTooltip(mouseEnterInfo) {
    let element = mouseEnterInfo.el;
    this.tooltip = document.createElement('div');

    let tooltipArrow = document.createElement('div');
    tooltipArrow.className = 'p-tooltip-arrow';
    this.tooltip.appendChild(tooltipArrow);

    let tooltipText = document.createElement('div');
    tooltipText.className = 'p-tooltip-text';

    tooltipText.innerHTML =
      '<div style="color:' +
      mouseEnterInfo.event.backgroundColor +
      '"><span>Arrival : ' +
      new Date(mouseEnterInfo.event.start).to24HrsTime() +
      ' </span> <br/> <span>Departure : ' +
      new Date(mouseEnterInfo.event.end).to24HrsTime() +
      ' </span></div>';

    this.tooltip.appendChild(tooltipText);

    document.body.appendChild(this.tooltip);

    this.tooltip.style.display = 'inline-block';
    this.tooltip.style.zIndex = ++DomHandler.zindex;

    this.alignRight(element);
    if (this.isOutOfBounds()) {
      this.alignBottom(element);
    }
  }

  getElementOffset(element) {
    let offset = element.getBoundingClientRect();
    let targetLeft = offset.left + DomHandler.getWindowScrollLeft();
    let targetTop = offset.top + DomHandler.getWindowScrollTop();
    return { left: targetLeft, top: targetTop };
  }

  isOutOfBounds(): boolean {
    let offset = this.tooltip.getBoundingClientRect();
    let targetTop = offset.top;
    let targetLeft = offset.left;
    let width = DomHandler.getOuterWidth(this.tooltip);
    let height = DomHandler.getOuterHeight(this.tooltip);
    let viewport = DomHandler.getViewport();
    return (
      targetLeft + width + 20 > viewport.width ||
      targetLeft < 0 ||
      targetTop < 0 ||
      targetTop + height > viewport.height
    );
  }

  alignRight(element) {
    this.preAlign('right');
    let hostOffset = this.getElementOffset(element);
    let left = hostOffset.left + DomHandler.getOuterWidth(element);
    let top =
      hostOffset.top +
      (DomHandler.getOuterHeight(element) -
        DomHandler.getOuterHeight(this.tooltip)) /
        2;
    this.tooltip.style.left = left + 'px';
    this.tooltip.style.top = top + 'px';
  }

  alignBottom(element) {
    this.preAlign('bottom');
    let hostOffset = this.getElementOffset(element);
    let left =
      hostOffset.left +
      (DomHandler.getOuterWidth(element) -
        DomHandler.getOuterWidth(this.tooltip)) /
        2;
    let top = hostOffset.top + DomHandler.getOuterHeight(element);
    this.tooltip.style.left = left + 'px';
    this.tooltip.style.top = top + 'px';
  }

  preAlign(position: string) {
    this.tooltip.style.left = -999 + 'px';
    this.tooltip.style.top = -999 + 'px';
    let defaultClassName = 'p-tooltip p-component p-tooltip-' + position;
    this.tooltip.className = defaultClassName;
  }
}
