export { }
declare global {
    interface Date {
        to24HrsFormattedString(): string;
        to24HrsTime(): string;
    }
}

Date.prototype.to24HrsFormattedString = function (): string {
    return this.toLocaleString('en-US', { hour12: false });
}

Date.prototype.to24HrsTime = function (): string {
    //let d = new Date(this.toLocaleString("en-US", { timeZone: "America/Toronto" }));
    return ('0' + this.getHours()).substr(-2) + ":" + ('0' + this.getMinutes()).substr(-2);
}