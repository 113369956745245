<div class="container">
  <div *ngIf="!showCrew">
    <div class="dropzone" filednd (fileDropped)="onFileDropped($event)">
      <input type="file" #fileDropRef id="fileDropRef" accept=".xls,.xlsx"
        (change)="fileBrowseHandler($event.target.files)" />
      <i class="fas fa-upload icon"></i>
      <h3 class="pt-3">Drag & drop file here</h3>
      <h3>or</h3>
      <button for="fileDropRef" class="btn">Browse</button>
    </div>

    <div *ngIf="shifts">
      <div class="row pt-2 toolbar">
        <div class="col-12">
          <div class="float-right d-flex align-items-center">
            <div class="c-pointer pr-3" (click)="deleteFile()">
              <i class="fas fa-trash-alt"></i>
            </div>
            <button type="button" class="p-button" (click)="onSubmit()"> Submit </button>
          </div>
        </div>
      </div>

      <p-table [value]="shifts" styleClass="p-datatable-striped">
        <ng-template pTemplate="header">
          <tr>
            <th>
              <div class="icon-title-th"><i class="fas fa-user-alt text-white "></i><label>Name</label></div>
            </th>
            <th>
              <div class="icon-title-th"><i class="fas fa-hourglass-start text-white"></i><label>Shift Start</label>
              </div>
            </th>
            <th>
              <div class="icon-title-th"><i class="fas fa-hourglass-end text-white"></i><label>Shift End</label></div>
            </th>
            <th>
              <div class="icon-title-th"><i class="fas fa-tools text-white"></i><label>Skills</label></div>
            </th>
            <th><label>Edit</label></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-record let-i="rowIndex">
          <tr>
            <td>{{ record.employee }}</td>
            <td>
              {{ record.startTime.slice(0, 2) }}:{{ record.startTime.slice(2) }}
            </td>
            <td>
              {{ record.endTime.slice(0, 2) }}:{{ record.endTime.slice(2) }}
            </td>
            <td>{{ record.skills }}</td>
            <td>
              <i class="p-color c-pointer pi pi-pencil" (click)="editShift(i)"></i>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <div class="col-12 pt-2 bottom-toolbar d-flex justify-content-between align-items-center">
        <div class="float-left">
          <span class="p-color c-pointer" (click)="addShift()"><i class="fas fa-plus" ></i> Add New Shift</span>
        </div>
        <div class="float-right d-flex align-items-center">
          <button type="button" class="p-button" (click)="onSubmit()">
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="scheduleData && showCrew">

    <div class="row pt-2 toolbar">
      <div class="col-12">
        <div class="float-right d-flex align-items-center">
          <div class="c-pointer text-link" (click)="exportLeadPDF()">Lead Report
            <i class="far fa-file-pdf pl-1 text-danger"></i>
          </div>
          <div class="c-pointer pl-2 text-link" (click)="exportSchedulePDF()">Schedule Report
            <i class="far fa-file-pdf pl-1 text-danger"></i>
          </div>
          <button type="button" class="btn btn-primary saveBtn" (click)="onSave()">Save</button>
        </div>
      </div>
    </div>
    <p-table [value]="scheduleData" styleClass="p-datatable-striped">
      <ng-template pTemplate="header">
        <tr>
          <th>
            <div class="icon-title-th"><i class="fas fa-clock text-white"></i><label>Arrival Time</label></div>
          </th>
          <th>
            <div class="icon-title-th"><i class="fas fa-clock-1 text-white"></i><label>Departure Time</label></div>
          </th>
          <th>
            <div class="icon-title-th"><i class="fas fa-plane-arrival text-white"></i><label>Arrival</label></div>
          </th>
          <th>
            <div class="icon-title-th"><i class="fas fa-plane-departure text-white"></i><label>Departure</label></div>
          </th>
          <th>
            <div class="icon-title-th"><i class="fas fa-plane text-white"></i><label>Type</label></div>
          </th>
          <th>
            <div class="icon-title-th"><i class="fas fa-user-tie-solid text-white"></i><label>Crew Lead</label></div>
          </th>
          <th>
            <div class="icon-title-th"><i class="fas fa-users-solid text-white"></i><label>Crew Members</label></div>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-record>
        <tr>
          <td>{{ record.arrivingFlight?.arrScheduledUtc | date: "medium" }}</td>
          <td>
            {{ record.departingFlight?.depScheduledUtc | date: "medium" }}
          </td>
          <td>{{ record.arrivingFlight?.flightNumberIcao }}</td>
          <td>{{ record.departingFlight?.flightNumberIcao }}</td>
          <td>{{ record.aircraftTypeIcao }}</td>
          <td>{{ record.crewLead?.name }}</td>
          <td>
            <div class="p-multiselect-token" *ngFor="let crew of record.crewMembers">
              <span class="p-multiselect-token-label">{{ crew.name }} &nbsp;</span>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <div class="col-12">
      <div class="float-right d-flex align-items-center bottom-toolbar">
        <button type="button" class="btn btn-primary saveBtn" (click)="onSave()">Save</button>
      </div>
    </div>
  </div>
</div>

<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button p-button-secondary"
  acceptButtonStyleClass="p-button"></p-confirmDialog>
<p-dialog [(visible)]="displayModal" modal="modal" [style]="{width: '50vw'}" [resizable]="false">
  <ng-template pTemplate="header">
    <h4>Update Schedule</h4>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="form-group row">
      <label for="employee-dropdown" class="col-sm-2 col-form-label">Name</label>
      <div class="col-sm-10">
        <p-dropdown id="employee-dropdown" [options]="employeeList" [(ngModel)]="selectedEmployee" optionLabel="name"
          optionValue="code" appendTo="body" [filter]="true" [class]="isValid ? '' : 'invalid-input'"
          (onChange)="validateEmployee()" required>
        </p-dropdown>
        <div *ngIf="!isValid" class="p-error">{{ selectedEmployee }} is already assigned to another shift.</div>
      </div>
    </div>
    <div class="form-group row">
      <label for="shifts" class="col-sm-2 col-form-label">Shift</label>
      <div class="col-sm-5">
        <div>from</div>
        <p-calendar id="shift-start" [(ngModel)]="shiftStart" optionLabel="shiftStart" [timeOnly]="true"
          [stepMinute]="15" appendTo="body"></p-calendar>
      </div>
      <div class="col-sm-5">
        <div for="shift-end">to</div>
        <p-calendar id="shift-end" [(ngModel)]="shiftEnd" optionLabel="shiftEnd" [timeOnly]="true" [stepMinute]="15"
          appendTo="body"></p-calendar>
      </div>
    </div>
  </ng-template>
  <p-footer>
    <div class="three-button-footer">
      <button class="p-button p-button-text p-color" (click)="confirmShiftDeletion()"> Delete </button>
      <div>
        <button class="p-button p-button-secondary" (click)="onHideModal()">Cancel</button>
        <button class="p-button" (click)="onShiftSave()" [disabled]="!isValid">{{ isEdit ? 'Update' :
          'Add'}}</button>
      </div>
    </div>
  </p-footer>
</p-dialog>