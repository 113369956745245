<div class="container">
    <div *ngIf="employeeData">
        <p-table [value]="employeeData" styleClass="p-datatable-striped">
            <ng-template pTemplate="header">
                <tr>
                  <th><div class="icon-title-th"><i class="fas fa-hashtag text-white"></i><label>Number</label></div></th>
                  <th><div class="icon-title-th"><i class="fas fa-user text-white"></i><label>Name</label></div></th>
                  <th  colspan="3"><div class="icon-title-th"><i class="fas fa-user-graduate text-white"></i><label>Qualifications</label></div></th>
                  <th style="width:8rem"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-record>
                <tr>
                    <td>{{record.employeeId}}</td>
                    <td>{{record.name}}</td>
                    <td colspan="3">
                      <div class="d-flex d-flex-row d-justify-content-around">
                        <div>
                          <div><strong>Crew Lead</strong></div>
                          <div class="p-multiselect-token" *ngFor="let qualification of record.qualifications">
                            <span *ngIf="qualification.displayName.includes('Crew Lead')" class="p-multiselect-token-label">{{qualification.displayName}} &nbsp;</span>
                          </div>
                        </div>
                        <div>
                          <div><strong>Crew Member</strong></div>
                          <div class="p-multiselect-token" *ngFor="let qualification of record.qualifications">
                            <span *ngIf="qualification.displayName.includes('Crew Member')" class="p-multiselect-token-label">{{qualification.displayName}} &nbsp;</span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td style="text-align:center">
                        <button *ngIf="!editing" pButton pRipple type="button" icon="pi pi-pencil"
                            (click)="onRowEditInit(record)" class="p-button-rounded p-button-text"></button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <p-dialog [(visible)]="displayDialog" modal="modal" [style]="{width: '50vw'}" scrollHeight="250px">
        <ng-template pTemplate="header">
            <h4>Update User Qualifications</h4>
        </ng-template>
        <div class="form-group row">
            <div class="col-4">
                Name:
            </div>
            <div class="col-8">
                {{selectedEmployee?.name}}
            </div>
        </div>
        <div class="form-group row">
            <div class="col-4">
                Qualifications:
            </div>
            <div class="col-8">
                <p-multiSelect appendTo="body" [options]="qualifications" [(ngModel)]="selectedQualifications"
                    [style]="{width: '100%'}" optionLabel="displayName" display="chip">
                </p-multiSelect>
            </div>
        </div>
        <p-footer>
            <button type="button" class="p-button p-button-secondary" (click)="onClose()">Close</button>
            <button type="button" class="p-button" (click)="onSave()">Update</button>
        </p-footer>
    </p-dialog>

</div>
