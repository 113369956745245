import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { AuthService } from './../services/auth.service'
import { MessageService } from 'primeng/api';
import { LoadingScreenService } from './../services/loading-screen.service'

@Injectable()
export class FlyhtHttpInterceptor implements HttpInterceptor {

    activeRequests: number = 0;

    skippUrls = [
        '/ authrefresh', //This is an example and will be replaced
    ];

    constructor(private auth: AuthService, private messageService: MessageService, private loadingScreenService: LoadingScreenService) { }

    private handleError(err: HttpErrorResponse): Observable<any> {
        if (err.status === 401 || err.status === 403) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'You do not have permission to perform this operation. Please contact your administrator for access.' });
            return of(err.message);
        } else if (err.status === 500) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'There was an error performing this task. Please contact your administrator.' });
            return of(err.message);
        }
        return throwError(err);
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let displayLoadingScreen: boolean = true;

        if (this.auth.userToken) {
            request = request.clone({
                headers: new HttpHeaders({
                    authorization: 'Bearer ' + this.auth.userToken,
                }),
            });
        }

        request.headers.append('Content-Type', 'application/json');
        for (const skippUrl of this.skippUrls) {
            if (new RegExp(skippUrl).test(request.url)) {
                displayLoadingScreen = false;
                break;
            }
        }
        if (displayLoadingScreen) {
            if (this.activeRequests === 0) {
                this.loadingScreenService.startLoading();
            }
            this.activeRequests++;
            return next.handle(request).pipe(
                finalize(() => {
                    this.activeRequests--;
                    if (this.activeRequests === 0) {
                        this.loadingScreenService.stopLoading();
                    }
                }),
                catchError((err) => this.handleError(err))
            );
        }
        else {
            return next.handle(request).pipe(catchError((err) => this.handleError(err)));
        }

    }
}
