<div class="container">
  <div class="aboveCalendar">
    <ul>
      <li>
        <div class="bullet on-time"></div>
        On Time
      </li>
      <li>
        <div class="bullet delayed"></div>
        Delayed
      </li>
      <li>
        <div class="bullet early"></div>
        Early
      </li>
      <li>
        <div class="bullet cancelled"></div>
        Cancelled
      </li>
    </ul>
    <p-multiSelect
      class="airlineFilter"
      [options]="airlines"
      [(ngModel)]="obsDataService.filteredAirlines"
      optionLabel="iata"
      selectedItemsLabel="{0} Selected"
      [filter]="false"
      (onChange)="airlineChange($event)"
    ></p-multiSelect>
  </div>

  <div>
    <full-calendar #calendar [options]="options"></full-calendar>
  </div>
</div>
