import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { AuthService } from './auth.service';
import { Subject } from 'rxjs';
import { TurnStatus } from './../common/app.enum';

@Injectable({
  providedIn: 'root',
})
export class ObservationDataService {
  public notification = new Subject<any>();
  private socket: any;
  private renewConnection: boolean = false;
  public initFLightData = new Subject<any>();
  public flyhtTurnUpdateData = new Subject<any>();
  public flyhtTurnDeleteData = new Subject<any>();
  public flyhtTurnNewData = new Subject<any>();

  public filteredAirlines: Array<any> = [];

  constructor(private authService: AuthService) {}

  get filteredAirlineICAOs(): Array<string> {
    return this.filteredAirlines.map((airline) => airline.icao);
  }

  fetchData() {
    if (!this.socket?.isOpen) {
      this.socket = new WebSocket(
        environment.flyhtSocket + this.authService.userToken
      );
      this.socket.onopen = (e) => {
        this.socket.isOpen = true;
        if (!this.renewConnection) {
          this.socket.send('{}');
        }
      };
    }

    this.socket.onmessage = (event) => {
      let data = JSON.parse(event.data);
      console.log(data);
      if (data.objectType === TurnStatus.NewTurnData) {
        this.flyhtTurnNewData.next(data);
      } else if (data.objectType === TurnStatus.UpdateTurnData) {
        this.flyhtTurnUpdateData.next(data);
      } else if (data.objectType === TurnStatus.DeleteTurnData) {
        this.flyhtTurnDeleteData.next(data);
      } else if (data.objectType === TurnStatus.Notification) {
        this.notification.next(data);
      }
    };

    this.socket.onerror = (error) => {};

    this.socket.onclose = (close) => {
      this.renewConnection = true;
      this.socket.isOpen = false;
      this.fetchData();
    };
  }
}
