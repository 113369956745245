import { Component, OnInit } from '@angular/core';
import { AuthService } from './../services/auth.service';
import { StyleManagerService } from './../services/style-manager.service';


@Component({
  selector: 'flyht-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements OnInit {

  toggle: boolean = false;
  url: string;

  constructor(public auth: AuthService, public styleManager: StyleManagerService) { }

  ngOnInit(): void {
    this.url = './global/svgs/Jetbridge_logo_dark.svg';
    this.styleManager.themeToggle.subscribe(themeName => {
      if (themeName === 'dark') {
        this.url = './global/svgs/Jetbridge_logo_dark.svg';
      } else {
        this.url = './global/svgs/Jetbridge_logo_light.svg';
      }
    });
  }

  toggleNavigation() {
    this.toggle = !this.toggle;
  }

  logout() {
    this.auth.logout();
  }
}
