import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class EventService {

  userInfo: any = {};

  constructor(public httpClient: HttpClient) { }

  getEvents() {
    return this.httpClient.get('./assets/events.json');
  }

  getInitFlighturns() {
    return this.httpClient.get(environment.initFlightTurn);
  }

  getUserDetails() {
    return this.httpClient.get(environment.userInfo);
  }

  setUserInfo(details) {
    this.userInfo = details;
  }

  uploadRosterData(data) {
    const uploadurl = environment.dailyRoster + this.userInfo.airportIcaoCode;
    return this.httpClient.put(uploadurl, data);
  }

  putCrewSchedule(data) {
    const scheduleCrewUrl = environment.scheduleCrew;
    return this.httpClient.put(scheduleCrewUrl, data);
  }

  postCrewSchedule(data) {
    const scheduleCrewUrl = environment.scheduleCrew;
    return this.httpClient.post(scheduleCrewUrl, data);
  }
}
