import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ConfirmationService } from 'primeng/api';

export interface ComponentCanDeactivate { canDeactivate: () => boolean | Observable<boolean>; }

@Injectable({ providedIn: 'root' }) export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {

  constructor(private confirmationService: ConfirmationService) { }

  canDeactivate(component: ComponentCanDeactivate): Observable<boolean> | boolean {
    if (component.canDeactivate()) {
      return of(true);
    }
    return new Observable((observer) => {
      this.confirmationService.confirm({
        message: 'WARNING: You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.',
        header: 'Confirm Action',
        key: 'pendingDialogConfirm',
        acceptLabel: 'Ok',
        rejectLabel: 'Cancel',
        reject: () => { observer.next(false); observer.complete(); },
        accept: () => { observer.next(true); observer.complete(); }
      });
    });

  }
}