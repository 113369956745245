import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { ComponentCanDeactivate } from '../common/pending-changes.guard';
import * as XLSX from 'xlsx';
import { EventService } from './../services/event.service';
import 'jspdf-autotable';
import jsPDF from 'jspdf';
import { DatePipe } from '@angular/common';
import { EmployeeService } from '../services/employee.service';
@Component({
  selector: 'app-schedular',
  templateUrl: './schedular.component.html',
  styleUrls: ['./schedular.component.scss']
})
export class SchedularComponent implements ComponentCanDeactivate, OnInit {

  selectedFile: any;
  allowedExtensions = ['xls', 'xlsx'];
  @ViewChild('fileDropRef') fileDropRef: ElementRef;
  fileProcessCompleted: boolean = false;
  shifts: any;
  scheduleData: any;
  showCrew: boolean = false;
  scheduleSaved: boolean = false;

  employeeList: any;
  selectedEmployee: any;
  isEdit: boolean = false;
  isValid: boolean = true;
  displayModal: boolean = false;
  displayConfirmation: boolean = false;

  modifiedShift: any = {}
  selectedShiftIndex: any = null;
  shiftStart: Date;
  shiftEnd: Date;

  constructor(private messageService: MessageService, private eventService: EventService,
    private datepipe: DatePipe, private employeeService: EmployeeService,
    private confirmationService: ConfirmationService) {

  }

  ngOnInit() {
    this.employeeService.getAllEmployees().subscribe((data: any) => {
      this.employeeList = data.message.map(item => {
        return {
          name: item.employeeId,
          code: item.employeeId
        }
      });
      console.log("It's schedule, %j", this.employeeList)
    });
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    if (this.fileProcessCompleted)
      return true;
    return (this.selectedFile == null);
  }

  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  prepareFilesList(files: Array<any>) {
    this.selectedFile = files[0];
    const fileExtension = this.selectedFile.name.split(".").pop();
    if (!this.allowedExtensions.includes(fileExtension)) {
      this.selectedFile = null;
      this.messageService.add({
        severity: 'error',
        summary: 'File upload error',
        detail: 'Please select Excel file to upload'
      });
    }
    else {
      this.importFile();
    }
  }

  confirmShiftDeletion() {
    this.confirmationService.confirm({
      message: `Do you want to delete ${this.selectedEmployee}'s shift?`,
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: `${this.selectedEmployee}'s shift deleted` });
        this.deleteShift()
      },
      reject: (type) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            // this.messageService.add({severity:'error', summary:'Rejected', detail:'You have rejected'});
            break;
          case ConfirmEventType.CANCEL:
            // this.messageService.add({severity:'warn', summary:'Cancelled', detail:'You have cancelled'});
            break;
        }
      }
    });
  }

  deleteFile() {
    this.confirmationService.confirm({
      message: `Do you want to delete the schedule?`,
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.fileDropRef.nativeElement.value = '';
        this.selectedFile = null;
        this.shifts = null;
      },
      reject: (type) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            // this.messageService.add({severity:'error', summary:'Rejected', detail:'You have rejected'});
            break;
          case ConfirmEventType.CANCEL:
            // this.messageService.add({severity:'warn', summary:'Cancelled', detail:'You have cancelled'});
            break;
        }
      }
    });
  }

  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  importFile() {
    let workBook = null;
    let jsonData = null;
    const reader: FileReader = new FileReader();
    reader.onload = (event) => {
      const data = event.target.result;
      workBook = XLSX.read(data, { type: 'binary' });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      console.log(jsonData);
      this.processShiftData(jsonData['Schedule']);
    };
    reader.readAsBinaryString(this.selectedFile);
  }

  addShift() {
    this.isEdit = false;

    this.selectedEmployee = this.shifts[0].employee;
    this.shiftStart = new Date();
    this.shiftStart.setHours(0, 0, 0, 0);
    this.shiftEnd = new Date();
    this.shiftEnd.setHours(0, 0, 0, 0);

    this.validateEmployee();
    this.displayModal = true;
  }

  editShift(index) {
    this.isEdit = true;
    this.selectedShiftIndex = index;
    this.modifiedShift = this.shifts[this.selectedShiftIndex]
    console.log(this.modifiedShift)

    this.selectedEmployee = this.modifiedShift.employee;
    this.shiftStart = new Date(Date.parse(this.modifiedShift.shiftStart));
    this.shiftEnd = new Date(Date.parse(this.modifiedShift.shiftEnd));

    this.displayModal = true;
  }

  onShiftSave() {
    this.modifiedShift.employee = this.selectedEmployee;

    this.modifiedShift.shiftStart = this.shiftStart;
    this.modifiedShift.startTime = this.getClockTime(this.shiftStart);

    this.modifiedShift.shiftEnd = this.shiftEnd;
    this.modifiedShift.endTime = this.getClockTime(this.shiftEnd);

    if (this.isEdit) {
      this.shifts[this.selectedShiftIndex] = this.modifiedShift
    } else {
      this.modifiedShift.skills = "Ramp Agent"
      this.shifts.push(this.modifiedShift)
    }

    this.onHideModal();
  }

  validateEmployee() {
    let selectedShift = this.shifts[this.selectedShiftIndex];

    // if a shift is selected
    // and the selected shift in dropdown is present in the shifts
    // and the selected shift in dropdown is not the currently edited employee in the shift
    if (selectedShift &&
      this.shifts.find(item => item.employee === selectedShift.employee) &&
      selectedShift.employee !== this.selectedEmployee) {
      this.isValid = false;
    } else {
      this.isValid = true;
    }
  }

  getClockTime(date: Date) {
    let hours = date.getHours().toString();
    if (hours.length === 1) {
      hours = "0" + hours;
    }

    let minutes = date.getMinutes().toString();
    if (minutes.length === 1) {
      minutes = "0" + minutes;
    }

    return `${hours}${minutes}`
  }

  deleteShift() {
    this.shifts.splice(this.selectedShiftIndex, 1)
    this.onHideModal()
  }

  onHideModal() {
    this.displayModal = false;
    this.selectedShiftIndex = null;
    this.isValid = true;
    this.isEdit = true;
  }

  processShiftData(jsonData) {
    let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    let rootObj = {};
    for (let index = 0; index < jsonData.length; index = index + 3) {
      let data = jsonData[index];
      let breaks = jsonData[index + 1];
      let skills = jsonData[index + 2];
      days.forEach(day => {
        if (data[day] !== "") {
          let tempObj = {};
          tempObj['employee'] = data.Label;
          tempObj['slots'] = data.Slots;
          tempObj['shiftTime'] = data[day];
          tempObj['startTime'] = data[day].split('-')[0];
          tempObj['endTime'] = data[day].split('-')[1];
          tempObj['break'] = breaks[day];
          tempObj['skills'] = skills[day];
          let shiftDuration = this.getDifference(tempObj['startTime'], tempObj['endTime']);
          tempObj['shiftStart'] = shiftDuration.start;
          tempObj['shiftEnd'] = shiftDuration.end;
          if (!rootObj[day]) {
            rootObj[day] = [];
          }
          rootObj[day].push(tempObj);
        }
      });
    }
    this.shifts = rootObj[days[new Date().getDay()]];
    this.eventService.uploadRosterData(this.shifts).subscribe(data => {
      this.fileProcessCompleted = true;
      console.log(data);
    });
  }

  getDifference(startTime, endTime) {
    let today = new Date().toLocaleDateString()
    startTime = startTime.match(/.{1,2}/g).join(':');
    endTime = endTime.match(/.{1,2}/g).join(':');
    let hours = startTime.split(':');
    let tempDate = new Date(today);
    tempDate.setHours(hours[0], hours[1]);
    let hoursStart = tempDate.getHours();
    hours = endTime.split(':');
    tempDate = new Date(today);
    tempDate.setHours(hours[0], hours[1]);
    let hoursEnd = tempDate.getHours();
    let hourDiff: number = hoursEnd - hoursStart;
    if (hourDiff < 0) {
      hourDiff = 24 + hourDiff;
    }
    hours = startTime.split(':');
    tempDate = new Date(today);
    tempDate.setHours(hours[0], hours[1]);
    hours = endTime.split(':');
    tempDate = new Date(today);
    tempDate.setHours(hours[0], hours[1]);
    let minsStart = tempDate.getMinutes();
    let minsEnd = tempDate.getMinutes();
    let minsDiff: number = minsEnd - minsStart;
    let shiftStart = new Date(today);
    hours = startTime.split(':');
    shiftStart.setHours(hours[0], hours[1]);
    let shiftEnd = new Date(today);
    shiftEnd.setHours(hours[0], hours[1]);
    shiftEnd.setHours(shiftEnd.getHours() + hourDiff);
    shiftEnd.setMinutes(shiftEnd.getMinutes() + minsDiff);
    return { start: shiftStart.toISOString(), end: shiftEnd.toISOString() }
  }

  onSubmit() {
    console.log(this.shifts);
    this.eventService.putCrewSchedule(this.shifts).subscribe((data: any) => {
      this.showCrew = true;
      this.scheduleData = data.message.turnView;
    });
  }

  onSave() {
    this.eventService.postCrewSchedule(this.scheduleData).subscribe((data: any) => {
      this.messageService.add({
        severity: 'success',
        summary: 'Success!',
        detail: 'Schedule have been saved.'
      });
    });
  }

  exportSchedulePDF() {
    let head = [['Arrival', 'Departure', 'Arr No.', 'Dep No.', 'Type', 'Lead', 'Crew']];
    let data = [];
    this.scheduleData.forEach(object => {
      let objData = [];
      objData.push(this.datepipe.transform(object.arrivingFlight?.arrScheduledUtc, 'MMM d, HH:mm'));
      objData.push(this.datepipe.transform(object.departingFlight?.depScheduledUtc, 'MMM d, HH:mm'));
      objData.push(object.arrivingFlight?.flightNumberIcao);
      objData.push(object.departingFlight?.flightNumberIcao);
      objData.push(object.aircraftTypeIcao);
      objData.push(object.crewLead?.name);
      objData.push(object.crewMembers?.map(a => a.name).join(', '));
      data.push(objData);
    });

    let doc = new jsPDF();

    doc.setFontSize(18);
    doc.text('Schedule - ' + this.datepipe.transform(new Date(), 'medium'), 11, 8);
    doc.setFontSize(11);
    doc.setTextColor(100);

    (doc as any).autoTable({
      head: head,
      body: data,
      theme: 'striped',
      didDrawCell: data => {
        console.log(data.column.index)
      }
    })

    // below line for Open PDF document in new tab
    doc.output('dataurlnewwindow')

    // below line for Download PDF document
    doc.save('myteamdetail.pdf');
  }

  exportLeadPDF() {
    let head = [['Lead', 'Arrival', 'Departure', 'Arr No.', 'Dep No.', 'Type', 'Crew']];
    let data = [];
    this.scheduleData.forEach(object => {
      let objData = [];
      objData.push(object.crewLead?.name);
      objData.push(this.datepipe.transform(object.arrivingFlight?.arrScheduledUtc, 'MMM d, HH:mm'));
      objData.push(this.datepipe.transform(object.departingFlight?.depScheduledUtc, 'MMM d, HH:mm'));
      objData.push(object.arrivingFlight?.flightNumberIcao);
      objData.push(object.departingFlight?.flightNumberIcao);
      objData.push(object.aircraftTypeIcao);
      objData.push(object.crewMembers?.map(a => a.name).join(', '));
      data.push(objData);
    });

    let doc = new jsPDF();

    doc.setFontSize(18);
    doc.text('Schedule - ' + this.datepipe.transform(new Date(), 'medium'), 11, 8);
    doc.setFontSize(11);
    doc.setTextColor(100);

    (doc as any).autoTable({
      head: head,
      body: data,
      theme: 'striped',
      didDrawCell: data => {
        console.log(data.column.index)
      }
    })

    // below line for Open PDF document in new tab
    doc.output('dataurlnewwindow')

    // below line for Download PDF document
    doc.save('myteamdetail.pdf');
  }

}
