import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { AuthService } from './services/auth.service';
import { FlyhtHttpInterceptor } from './common/http.interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DropDownDirective } from './common/dropdown.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { ToastModule } from 'primeng/toast';
import { MessageService, ConfirmationService } from 'primeng/api';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TooltipModule } from 'primeng/tooltip';
import './common/common-methods';
import { LoadingScreenComponent } from './common/loading-screen/loading-screen.component';
import { SchedularComponent } from './schedular/schedular.component';
import { FiledndDirective } from './common/filednd.directive';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { PendingChangesGuard } from './common/pending-changes.guard';
import { UserResolver } from './resolvers/user.resolver';
import { TableModule } from 'primeng/table';
import { EmployeeComponent } from './employee/employee.component';
import { DialogModule } from 'primeng/dialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { DatePipe } from '@angular/common';
import { ThemePickerComponent } from './theme-picker/theme-picker.component';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';



FullCalendarModule.registerPlugins([
  dayGridPlugin,
  timeGridPlugin,
  listPlugin,
  interactionPlugin,
  resourceTimelinePlugin
]);
@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    FooterComponent,
    HomeComponent,
    DropDownDirective,
    LoadingScreenComponent,
    SchedularComponent,
    FiledndDirective,
    EmployeeComponent,
    BreadcrumbsComponent,
    ThemePickerComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    FullCalendarModule,
    ToastModule,
    ProgressSpinnerModule,
    HttpClientModule,
    TooltipModule,
    ConfirmDialogModule,
    TableModule,
    DialogModule,
    MultiSelectModule,
    BreadcrumbModule,
    CalendarModule,
    DropdownModule,
    InputSwitchModule
  ],
  providers: [AuthService, MessageService, ConfirmationService, PendingChangesGuard, UserResolver, DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: FlyhtHttpInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
