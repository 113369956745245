import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { StyleManagerService } from '../services/style-manager.service';

@Component({
  selector: 'app-theme-picker',
  templateUrl: './theme-picker.component.html',
  styleUrls: ['./theme-picker.component.scss']
})
export class ThemePickerComponent implements OnInit {

  @Output() themeChanged = new EventEmitter<string>();
  isdarkTheme: boolean = true;
  
  constructor(public styleManager: StyleManagerService) { }

  ngOnInit(): void {
    this.installTheme('dark');
  }

  installTheme(themeName: string) {
    this.styleManager.setStyle('theme', `/assets/${themeName}.css`);
  }

  toggleTheme(e) {
    let themeName = !e.checked ? 'light' : 'dark';
    this.styleManager.setStyle('theme', `/assets/${themeName}.css`);
    this.themeChanged.emit(e.checked);
    this.styleManager.themeToggle.next(themeName);
  }

}

