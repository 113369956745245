import { Injectable } from '@angular/core';
import * as auth0 from 'auth0-js';
import { environment } from './../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // Create Auth0 web auth instance
  auth0 = new auth0.WebAuth({
    clientID: environment.auth.clientID,
    domain: environment.auth.domain,
    responseType: 'token',
    redirectUri: environment.auth.redirect,
    scope: environment.auth.scope
  });
  // Store authentication data
  expiresAt: number;
  userProfile: any;
  accessToken: string;
  userToken: string;
  authenticated: boolean;
  refreshSubscription: any;

  constructor(private router: Router) { }

  login() {
    // Auth0 authorize request
    this.auth0.authorize();
  }

  handleLoginCallback() {
    // When Auth0 hash parsed, get profile
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken) {
        window.location.hash = '';
        this.getUserInfo(authResult);
      } else if (err) {
        //console.error(`Error: ${err.error}`);
      }
    });
  }

  getUserInfo(authResult) {
    // Use access token to retrieve user's profile and set session
    this.auth0.client.userInfo(authResult.accessToken, (err, profile) => {
      if (profile) {
        this._setSession(authResult, profile);
        this.scheduleRenewal();
        this.router.navigate(['/home']);
      }
    });
  }

  private _setSession(authResult, profile) {
    // Save authentication data and update login status subject
    this.expiresAt = authResult.expiresIn * 1000 + Date.now();
    this.userToken = authResult.idToken;
    this.accessToken = authResult.accessToken;
    this.userProfile = profile;
    this.authenticated = true;
  }

  logout() {
    // Log out of Auth0 session
    // Ensure that returnTo URL is specified in Auth0
    // Application settings for Allowed Logout URLs
    this.auth0.logout({
      returnTo: environment.auth.redirect,
      clientID: environment.auth.clientID
    });
    this.unscheduleRenewal();
  }

  get isLoggedIn(): boolean {
    // Check if current date is before token
    // expiration and user is signed in locally
    return Date.now() < this.expiresAt && this.authenticated;
  }

  public scheduleRenewal() {
    if (!this.authenticated) return;

    if (!this.refreshSubscription) {
      this.refreshSubscription = setInterval(() => {
        console.log('Inside RenewToken-------- at ' + new Date());
        this.renewToken();
      }, ((environment.idTokenValidSeconds - 60) * 1000));
    }
  }

  public renewToken() {
    this.auth0.checkSession({
      audience: this.auth0.audience
    }, (err, result) => {
      if (err) {
        console.log(`Could not get a new token using silent authentication (${err.error}).`);
      } else {
        console.log('Successfully renewed auth! at ' + new Date());
        this.getUserInfo(result);
      }
    });
  }

  public unscheduleRenewal() {
    if (!this.refreshSubscription) return;
    clearInterval(this.refreshSubscription);
  }

}
