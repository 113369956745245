<div *ngIf="auth.authenticated">

  <div class="main-content">
    <flyht-nav-menu></flyht-nav-menu>

    <div class="main">
      <flyht-breadcrumbs></flyht-breadcrumbs>

      <div class="router-outlet">
        <router-outlet></router-outlet>
      </div>
    </div>

    <flyht-footer></flyht-footer>
  </div>

  <p-toast [baseZIndex]="500000" styleClass="custom-toast"></p-toast>
  <p-toast [baseZIndex]="500000" position="bottom-right" key="br"></p-toast>

  <div class="loadingContainer" *ngIf="loading">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
      animationDuration=".5s"></p-progressSpinner>
  </div>

  <p-confirmDialog [style]="{width: '50vw'}" key="pendingDialogConfirm" [position]="'top'" [baseZIndex]="100000">
  </p-confirmDialog>

</div>
