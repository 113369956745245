<nav class="navbar topnav navbar-expand-md">
    <a class="navbar-brand" href="#"><img alt="Flyht" [src]="url" /></a>
    <button class="navbar-toggler" type="button" (click)="toggleNavigation()">
        <i class="fa fa-bars icon"></i>
    </button>
    <div class="collapse navbar-collapse" [ngClass]="toggle ? 'show' : ''">
        <ul class="navbar-nav">
            <li class="nav-item">
                <a [routerLink]='["home/schedule"]'>Schedule</a>
            </li>
            <li class="nav-item">
                <a [routerLink]='["home/user"]'>Qualifications</a>
            </li>
            <li class="nav-item d-md-none">
                <span class="btn" (click)="logout()"><i class="fas fa-sign-out-alt icon"></i> Logout</span>
            </li>
        </ul>

        <div *ngIf="auth.userProfile?.picture" class="ml-auto dpMenu p-0 d-none d-md-block" appDropDown
            #r="appDropDown">
            <button class="btnMenuContainer p-0">
                <i class="fas fa-user-alt icon"></i>
            </button>
            <div class="dpMenuContent p-2" [ngClass]="{'d-none':!r.isOpen}">
                <app-theme-picker></app-theme-picker>
                <span class="pt-1" (click)="logout()"><i class="fas fa-sign-out-alt icon"></i> Logout</span>
            </div>
        </div>
    </div>
</nav>
