import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import qs from 'qs';
import { LoadingScreenService } from './services/loading-screen.service';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ObservationDataService } from './services/observation-data.service';
import { MessageService } from 'primeng/api';
import { AppConstants } from './common/appConstants';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  loading: boolean = false;
  loadingSubcription: Subscription;

  constructor(
    public auth: AuthService,
    public loadingScreenService: LoadingScreenService,
    private obsService: ObservationDataService,
    private messageService: MessageService,
    private appConstants: AppConstants
  ) {}

  ngOnInit() {
    if (
      !this.auth.accessToken ||
      this.auth.expiresAt < new Date().getTime() / 1000
    ) {
      let hashStr = window.location.hash;
      hashStr = hashStr.replace(/^#?\/?/, '');
      let parsedHash = qs.parse(hashStr);
      if (!parsedHash || !parsedHash.access_token || !parsedHash.id_token) {
        this.auth.login();
      } else {
        this.auth.handleLoginCallback();
      }
    }

    this.loadingSubcription = this.loadingScreenService.loadingStatus
      .pipe(debounceTime(200))
      .subscribe((value) => {
        this.loading = value;
      });

    this.obsService.notification.subscribe((data) => {
      console.log('notification data', data);
      console.log('filteredAirlineICAOs', this.obsService.filteredAirlineICAOs);
      console.log('airlineIcao', data.airlineIcao);
      if (this.obsService.filteredAirlineICAOs.includes(data.airlineIcao)) {
        this.messageService.add({
          severity: 'custom',
          summary: data.notificationTitle,
          life: this.appConstants.notificationDuration,
          detail:
            data.eventMessage +
            ' ' +
            new Date(data.eventUtc)
              .toLocaleString('en-US', { timeZone: 'America/Toronto' })
              .replace(/:\d{2}\s/, ' '),
        });
      }
    });
  }

  ngOnDestroy() {
    this.loadingSubcription.unsubscribe();
  }
}
