import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  constructor(public httpClient: HttpClient) { }

  getAllEmployees() {
    return this.httpClient.get(environment.employeeQualifications);
  }

  getAllQualifications() {
    return this.httpClient.get(environment.qualifications);
  }

  updateEmployeeQualifications(empObject) {
    return this.httpClient.put(environment.employeeQualifications, empObject);
  }

}
