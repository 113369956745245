import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { SchedularComponent } from './schedular/schedular.component';
import { AuthGuard } from './common/auth.guard';
import { PendingChangesGuard } from './common/pending-changes.guard';
import { UserResolver } from './resolvers/user.resolver';
import { EmployeeComponent } from './employee/employee.component';

const routes: Routes = [
  {
    path: 'home',
    data: {
      breadcrumb: "Turn Process Manager"
    },
    children: [
      {
        path: '',
        component: HomeComponent,
        canActivate: [AuthGuard],
        resolve: { user: UserResolver },
        data: {
          breadcrumb: null
        }
      },
      {
        path: 'schedule',
        data: {
          breadcrumb: 'Schedule Manager'
        },
        component: SchedularComponent,
        canActivate: [AuthGuard],
        canDeactivate: [PendingChangesGuard],
      },
      {
        path: 'user',
        component: EmployeeComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Employee Manager'
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
